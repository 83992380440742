
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'stepper-nav',
  props: {
    colClass: {
      type: String,
      default: '',
    },
    stepperNo: {
      type: String,
      default: '',
    },
    stepperTitle: {
      type: String,
      default: '',
    },
    stepperDescription: {
      type: String,
      default: '',
    },
  },
});
