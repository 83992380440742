
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/enums/VerifyAccountEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import CompleteProfileWizard from '@/views/new-design/CompleteProfile/WizardForm.vue';
import store from '@/store';

export default defineComponent({
  name: 'complete-your-profile-page',
  emits: ['handleCodeInput'],
  data: () => ({
    loading: false,
  }),
  mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  components: {
    CompleteProfileWizard,
  },
  computed: {
    ...mapGetters({
      token: Getters.GET_VERIFY_ACCOUNT_TOKEN,
      errors: Getters.GET_ACTION_ERROR,
    }),
  },

  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
