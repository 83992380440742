
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import {
  Getters as PlanGetters,
  Actions as PlanActions,
} from '@/store/enums/PlanEnums';

import {
  Actions as PaymentActions,
  Getters as PaymentGetters,
} from '@/store/enums/PaymentEnums';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { Plan } from '@/models/PlanModel';
import { numberFormat } from '@/utils/text';
import toasts from '@/utils/toasts';

const temporaryDescriptions = [
  'Submit Requested Tenant References',
  'Submit Unlimited Tenant References',
  'Search for Tenant References',
  'View Full History of a Tenant Reference',
  'Request Tenant References',
  'Create Unlimited Number of Users/PMs',
];

export default defineComponent({
  name: 'view-plan-detail-table',
  emits: ['selected'],
  props: {
    planCode: {
      type: String,
      default: '',
    },
    showFree: {
      type: Boolean,
      default: true,
    },
    isChange: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    current: 'month',
    selected: 'free',
    default: 'free',
    loadTable: false,
    loadStart: false,
    planType: 'monthly',
    showFreePlan: true,
    planDescriptions: temporaryDescriptions,
  }),
  async mounted() {
    await this.handleLoadPlans();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      listPlans: PlanGetters.GET_PLANS,
      checkoutErrors: PaymentGetters.GET_ACTION_ERROR,
    }),
    getPlanType() {
      return this.planType;
    },
    sortedPlans() {
      let plans = [...this.listPlans];

      if (this.showFreePlan === false) {
        plans = plans.filter((plan) => plan.code.toLowerCase() !== 'free');
      }

      return plans.sort((a, b) => {
        return parseFloat(a.monthly_price) - parseFloat(b.monthly_price);
      });
    },

    planServices() {
      const arr = this.sortedPlans.map((item) => {
        return item.description;
      });

      return _.uniq(_.flattenDeep(arr)) as unknown as Plan;
    },

    pricingTable() {
      const pricingLists = {} as any;

      this.planDescriptions.map((desc) => {
        const priceMap = [] as any;
        this.sortedPlans.map((plan) => {
          priceMap[plan.code] = plan.description.includes(desc);
        });

        const key = desc.toLowerCase().replace(/\s/g, '_');
        pricingLists[key] = { ...priceMap };
      });

      return pricingLists;
    },
  },
  methods: {
    ...mapActions({
      fetchPlans: PlanActions.FETCH_PLANS,
      checkout: PaymentActions.DO_CHECKOUT,
      changePlan: PaymentActions.DO_CHANGE_PLAN,
    }),
    formatMoney(amount) {
      return numberFormat(amount, 2);
    },
    async handleLoadPlans() {
      if (this.loadTable) return;
      this.loadTable = true;

      return await this.fetchPlans().then(() => {
        this.loadTable = false;
      });
    },
    choosePlanType(type) {
      this.planType = type;
    },
    handleCheckout() {
      if (this.loadStart) return;
      this.loadStart = true;

      const payload = {
        code: this.selected,
        planType: this.planType,
        draw: this.$route?.query?.draw,
      };

      return (this.isChange ? this.changePlan : this.checkout)(payload)
        .then((data) => {
          if (this.isChange) {
            return toasts.success('Subscription successfully changed.', () => {
              this.$router.push({ name: 'subscription-tab' });
            });
          }
          window.location = data.checkout.url as unknown as Location;
        })
        .catch(() => {
          this.loadStart = false;
          const { errors, message } = this.checkoutErrors;
          toasts.error(errors, message);
        });
    },

    calculatePrice(price, discount) {
      if (discount !== '0' && discount !== null) {
        return parseFloat(price) * parseFloat(discount);
      }

      return parseFloat(price).toFixed(0);
    },
    cancel() {
      return this.$router.replace({ path: 'dashboard' });
    },

    handleSelected(code) {
      this.selected = code;
      this.$emit('selected', code);
    },
  },
  watch: {
    planCode: {
      async handler(value) {
        this.selected = await value;
      },
      immediate: true,
      deep: true,
    },
    showFree: {
      async handler(values) {
        this.showFreePlan = await values;
      },
      immediate: true,
      deep: true,
    },
  },

  expose: ['selected'],
});
