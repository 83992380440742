
import { defineComponent } from 'vue';
import PersonalDetails from '@/views/new-design/CompleteProfile/ProfileForm.vue';
import AddressForm from '@/views/new-design/CompleteProfile/AddressForm.vue';

export default defineComponent({
  name: 'owner-details',
  emits: [
    'ownerStateChange',
    'ownerStateBlur',
    'ownerCountryChange',
    'ownerCountryBlur',
  ],
  props: {
    values: {
      type: Object,
      default: () => undefined,
    },
  },
  data: () => ({
    states: [],
  }),
  components: {
    PersonalDetailsForm: PersonalDetails,
    OwnerAddressForm: AddressForm,
  },
  computed: {
    formLabels() {
      return {
        address_line: 'Address Line',
        city: 'Suburb',
        state: 'State',
        post_code: 'Post Code',
      };
    },
  },
  methods: {
    handleInput(e) {
      console.log('handleInput', e);
    },
    handleCountryChange(value) {
      this.$emit('ownerCountryChange', value);
    },
    handleCountryBlur() {
      this.$emit('ownerCountryBlur');
    },
    handleStateChange(value) {
      this.$emit('ownerStateChange', value);
    },
    handleStateBlur() {
      this.$emit('ownerStateBlur');
    },
  },
});
