
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';
import maskUtil from '@/utils/mask';
import date from '@/utils/dates';

export default defineComponent({
  name: 'personal-detail-form',
  emits: ['input'],
  props: {
    values: {
      type: Object,
      default: () => {
        undefined;
      },
    },
  },
  components: {
    FormField,
  },
  computed: {
    maskingMobile() {
      return maskUtil.mobile.aus;
    },
    dateFormat() {
      return date.defaultFormat;
    },
  },
  methods: {
    disableFutureDates(time: Date) {
      return time.getTime() > Date.now();
    },
    handleEmailInput() {
      return true;
    },
  },
});
