
import { defineComponent } from 'vue';
import PlanColumnSelectionView from '@/components/tables/PlanColumnSelectionView.vue';

export default defineComponent({
  name: 'subscription-details',
  props: {
    values: {
      type: Object,
      default: () => undefined,
    },
  },
  emits: ['planSelected'],
  components: {
    PlanColumnSelectionView,
  },
  methods: {
    handleSelectedPlan(code) {
      this.$emit('planSelected', code);
    },
  },
});
