import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_States = _resolveComponent("States")!
  const _component_Countries = _resolveComponent("Countries")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-column mb-10", _ctx.containerClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormField, {
        required: true,
        name: _ctx.addPrefix('address_line', _ctx.prefix),
        containerClass: "col-lg-12 col-sm-12 col-xs-12",
        label: _ctx.getLabel('address_line'),
        placeholder: _ctx.getLabel('address_line'),
        type: "text"
      }, null, 8, ["name", "label", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormField, {
        required: true,
        name: _ctx.addPrefix('city', _ctx.prefix),
        containerClass: "col-lg-6 col-sm-12 col-xs-12",
        label: _ctx.getLabel('city'),
        placeholder: _ctx.getLabel('city'),
        type: "text"
      }, null, 8, ["name", "label", "placeholder"]),
      _createVNode(_component_States, {
        required: true,
        options: _ctx.options,
        value: _ctx.state,
        name: _ctx.addPrefix('state', _ctx.prefix),
        containerClass: "col-lg-6 col-sm-12 col-xs-12",
        label: _ctx.getLabel('state'),
        placeholder: _ctx.getLabel('state'),
        onChange: _ctx.handleStateSelect,
        onClose: _ctx.handleStateClose
      }, null, 8, ["options", "value", "name", "label", "placeholder", "onChange", "onClose"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormField, {
        required: true,
        name: _ctx.addPrefix('post_code', _ctx.prefix),
        mask: '####',
        containerClass: "col-lg-6 col-sm-12 col-xs-12",
        label: _ctx.getLabel('post_code'),
        placeholder: _ctx.getLabel('post_code'),
        type: "text"
      }, null, 8, ["name", "label", "placeholder"]),
      _createVNode(_component_Countries, {
        required: true,
        value: _ctx.country,
        options: _ctx.countries,
        name: _ctx.addPrefix('country', _ctx.prefix),
        label: _ctx.getLabel('country'),
        placeholder: _ctx.getLabel('country'),
        containerClass: "col-lg-6 col-sm-12 col-xs-12",
        onChange: _ctx.handleCountrySelect,
        onClose: _ctx.handleCountryClose
      }, null, 8, ["value", "options", "name", "label", "placeholder", "onChange", "onClose"])
    ])
  ], 2))
}