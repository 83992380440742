
import { mergeSchemas } from '@/utils/validations';
import { defineComponent } from 'vue';
import { StepperComponent } from '@/assets/ts/components';
import { Form } from 'vee-validate';
import StepperNav from '@/views/new-design/CompleteProfile/Stepper.vue';
import OwnerDetails from '@/views/new-design/CompleteProfile/OwnerDetails.vue';
import SubscriptionDetails from '@/views/new-design/CompleteProfile/SubscriptionDetails.vue';

import {
  Owner,
  Address,
  Business,
} from '@/views/new-design/validations/CompleteProfile';

import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import { Getters as PrincipalGetters } from '@/store/enums/PrincipalEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import { mapActions, mapGetters } from 'vuex';
import { Principal } from '@/models/PrincipalModel';
import store from '@/store';
import toasts from '@/utils/toasts';
import moment from 'moment';
import router from '@/router';
import {
  Actions as PaymentActions,
  Getters as PaymentGetters,
} from '@/store/enums/PaymentEnums';

const initialValues = {
  mobile_number: '',
  state: '',
  country: 'AU',
  business_state: '',
  business_country: 'AU',
} as unknown as Principal;

export default defineComponent({
  name: 'complete-account-profile-wizard',
  props: {
    colClass: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    currentStep: 0,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    stepper: {} as StepperComponent,
    selectedPlan: 'free',
    planType: 'monthly',
  }),
  components: {
    Form,
    StepperNav,
    Step1: OwnerDetails,
    Step2: SubscriptionDetails,
  },
  mounted() {
    // setup the form reference
    this.form = this.$refs.completeProfileForm as typeof Form;

    // setup the stepper
    this.stepper = StepperComponent.createInsance(
      this.$refs.completeProfileStepperForm as HTMLElement
    ) as StepperComponent;

    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  computed: {
    ...mapGetters({
      user: AuthGetters.GET_AUTH_USER,
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
      principal: PrincipalGetters.GET_PRINCIPAL,
      checkoutErrors: PaymentGetters.GET_ACTION_ERROR,
    }),
    formStepSchema() {
      return this.formSchema[this.currentStep];
    },

    formSchema() {
      const PersonalDetailSchemas = mergeSchemas(Owner, Address);

      return [PersonalDetailSchemas, Business];
    },
    initValues() {
      return {
        ...this.formData,
        ...{
          email: this.user.email,
          country: this.user.country ? this.user.country : 'AU',
          business_country: this.user.business_country
            ? this.user.business_country
            : 'AU',
        },
      };
    },
    totalSteps(): number {
      return 2;
    },

    buttonText() {
      return this.selectedPlan !== 'free' ? 'Subscribe' : 'Save';
    },
  },
  methods: {
    ...mapActions({
      completeProfile: AuthActions.SUBMIT_COMPLETE_PROFILE,
      fetchAuthUser: AuthActions.FETCH_AUTH_USER,
      checkout: PaymentActions.DO_CHECKOUT,
    }),

    handleOwnerStateChange(value) {
      this.setFieldValue('state', value);
    },
    handleOwnerCountryChange(value) {
      this.setFieldValue('country', value);
    },
    handleBusinessStateChange(value) {
      this.setFieldValue('business_state', value);
    },
    handleBusinessCountryChange(value) {
      this.setFieldValue('business_country', value);
    },
    handleOwnerCountryBlur() {
      this.validateFormField('country');
    },
    handleOwnerStateBlur() {
      this.validateFormField('state');
    },
    handleBusinessCountryBlur() {
      this.validateFormField('business_country');
    },
    handleBusinessStateBlur() {
      this.validateFormField('business_state');
    },
    handlePlanSelected(code) {
      this.selectedPlan = code;
    },
    skipCompleteProfile() {
      router.push({ name: 'dashboard' });
    },
    nextStep(values) {
      this.formData = {
        ...this.formData,
        ...values,
        ...{
          business_country: values.business_country ?? 'AU',
        },
      };

      this.currentStep++;

      if (!this.stepper) {
        return;
      }

      this.form.resetForm();
      this.stepper.goNext();
    },
    previousStep() {
      if (!this.stepper) return;

      this.currentStep--;
      this.stepper.goPrev();
    },
    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form stepper
      this.currentStep = 0;
      this.stepper.goFirst();

      // Reset the form values
      this.form.setValues(initialValues);
      this.formData = initialValues;
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    async submitCompleteProfileForm(values) {
      // const formErrors = await validate().then(async (response) => {
      //   return await response;
      // });

      //if (!formErrors.valid) return;

      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...this.formData,
        ...values,
        // ...{
        //   date_of_birth: this.formData.date_of_birth
        //     ? moment(this.formData.date_of_birth).format('YYYY-MM-DD')
        //     : '',
        // },
      };

      /**
       * Delete pre-filled ID from review
       *
       * Note: Do not remove
       */
      delete this.formData.id;
      // End: Delete pre-filled ID from review

      this.completeProfile(this.formData)
        .then(async () => {
          this.loading = false;
          if (this.selectedPlan !== 'free') {
            toasts.success(
              'Account profile completed! Please wait while being redirected to payment page...'
            );
            this.handleCheckout();

            return;
          }
          toasts.success('Account profile completed!', () => {
            this.resetForm();
          });
          await this.fetchAuthUser();
          router.push({ name: 'dashboard' });
        })
        .catch(() => {
          this.loading = false;
          const { errors, messages } = this.actionErrors;
          toasts.error(errors, messages);
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },

    handleCheckout() {
      const payload = {
        code: this.selectedPlan,
        planType: this.planType,
      };

      return this.checkout(payload)
        .then((data) => {
          window.location = data.checkout.url as unknown as Location;
        })
        .catch(() => {
          const { errors, message } = this.checkoutErrors;
          toasts.error(errors, message);
        });
    },
  },
  watch: {
    user(value) {
      if (!value) return;

      this.formData = {
        ...this.formData,
        ...value,
        ...{
          email: value.email ?? value.email,
          country: value.country ?? 'AU',
          business_country: value.business_country ?? 'AU',
        },
      };
    },
  },
});
