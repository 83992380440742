enum Actions {
  GET_ALL_PRINCIPALS = 'retrieveAllPrincipal',
  SEARCH_PRINCIPAL = 'searchPrincipal',
  FETCH_PRINCIPAL = 'fetchPrincipal',
}

enum Mutations {
  SET_SEARCH_PRINCIPAL_LIST = 'setSearchPrincipalList',
  SET_ALL_PRINCIPALS = 'setAllPrincipals',
  SET_PRINCIPAL = 'setPrincipal',
  SET_EXISTING_PRINCIPAL = 'setExistingPrincipal',
  SET_PRINCIPALS = 'setPrincipals',
  SET_LIST_ERROR = 'setPrincipalListError',
  SET_ACTION_ERROR = 'setPrincipalActionError',
  SET_LIST_LOADING = 'setPrincipalListLoading',
}

enum Getters {
  GET_SEARCH_PRINCIPAL_LIST = 'getSearchPrincipalList',
  GET_ALL_PRINCIPALS = 'getAllPrincipals',
  GET_PRINCIPALS = 'getPrincipals',
  GET_PRINCIPAL = 'getPrincipal',
  GET_EXISTING_PRINCIPAL = 'getExistingPrincipal',
  GET_SEARCH_PRINCIPAL_ACTION_ERROR = 'getSearchPrincipalActionError',
  GET_PRINCIPAL_ACTION_ERROR = 'getPrincipalActionError',
  GET_PRINCIPAL_LIST_ERROR = 'getPrincipalListError',
  GET_PRINCIPAL_LIST_LOADING = 'getPrincipalListLoading',
  GET_PRINCIPAL_LOADING = 'getPrincipalLoading',
}

export { Actions, Mutations, Getters };
