import moment from 'moment';

export const defaultFormat = 'DD/MM/YYYY';

export const today = (displayFormat: string = defaultFormat, raw = false) =>
  raw ? new Date() : moment(new Date()).format(displayFormat);

export default {
  defaultFormat,
  today,
};
